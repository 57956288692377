module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website"},"twitter":{"cardType":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de-de","en-us","en-gb","en-au","es-es","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","vi-vn","zh-tw"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"ns":["second","first","404"],"defaultNS":"first","load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?/example-one","getLanguageFromPath":true,"excludeLanguages":["de-de","en-us","en-gb","en-au","es-es","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","vi-vn","zh-tw"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["de-de","en-us","en-gb","en-au","es-es","es-ar","es-mx","fr-fr","it-it","ja-jp","ko-kr","pl-pl","pt-br","ru-ru","th-th","tr-tr","vi-vn","zh-tw"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TR65XVQ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hextech Mayhem","short_name":"Hextech Mayhem","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/jfg-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4a81b6af95dc9da36967475d35044b6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
